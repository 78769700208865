import { useEffect, useState } from 'react';
import { WizardPicto } from '@platform-storybook/circlestorybook';
import styles from './create-order-page.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { ordersActions } from '../../../store/orders/orders.reducer';
import PatientFilesForm from './patient-files-form/PatientFilesForm';
import ProthesesForm from './protheses-form/ProthesesForm.tsx';
import DesignStep from './design-step/DesignStep.tsx';
import { faCubes, faPaperclip, faTooth, faDownload } from '@fortawesome/pro-light-svg-icons';
import {
  faCubes as faCubesSolid,
  faPaperclip as faPaperSolid,
  faTooth as faToothSolid,
  faDownload as faDownloadSolid
} from '@fortawesome/pro-solid-svg-icons';
import { WizardPictoStep } from '../../../models/navigation.tsx';
import { WizardPictoStatusEnum } from '../../../enum/navigation.enum.ts';
import ExportStep from './download-step/ExportStep.tsx';
const CreateOrderPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['order']);
  const [selectedStep, setSelectedStep] = useState<number>(0);
  const [stepsPicto, setStepsPicto] = useState<Array<WizardPictoStep>>([
    {
      id: 'files',
      label: t('createOrder.wizard.patient-files'),
      status: WizardPictoStatusEnum.ACTIVE,
      icon: faPaperSolid
    },
    {
      label: t('createOrder.wizard.prosthesis'),
      id: 'prosthesis',
      status: WizardPictoStatusEnum.DISABLED,
      icon: faTooth
    },
    {
      label: t('createOrder.wizard.design'),
      id: 'design',
      status: WizardPictoStatusEnum.DISABLED,
      icon: faCubes
    },
    {
      label: t('createOrder.wizard.export'),
      id: 'export',
      status: WizardPictoStatusEnum.DISABLED,
      icon: faDownload
    }
  ]);

  useEffect(() => {
    dispatch(ordersActions.resetOrder());
  }, []);

  const navigateNextStep = () => {
    setSelectedStep(selectedStep + 1);

    if (selectedStep === 0) {
      setStepsPicto([
        { ...stepsPicto[0], status: WizardPictoStatusEnum.VALID, icon: faPaperclip },
        { ...stepsPicto[1], status: WizardPictoStatusEnum.ACTIVE, icon: faToothSolid },
        { ...stepsPicto[2] },
        { ...stepsPicto[3] }
      ]);
    } else if (selectedStep === 1) {
      setStepsPicto([
        { ...stepsPicto[0] },
        { ...stepsPicto[1], status: WizardPictoStatusEnum.VALID, icon: faTooth },
        { ...stepsPicto[2], status: WizardPictoStatusEnum.ACTIVE, icon: faCubesSolid },
        { ...stepsPicto[3] }
      ]);
    } else if (selectedStep === 2) {
      setStepsPicto([
        { ...stepsPicto[0] },
        { ...stepsPicto[1] },
        { ...stepsPicto[2], status: WizardPictoStatusEnum.VALID, icon: faCubes },
        { ...stepsPicto[3], status: WizardPictoStatusEnum.ACTIVE, icon: faDownloadSolid }
      ]);
    }
  };

  return (
    <div className={styles['create-order-page']}>
      <div className={styles['create-order-page__body']}>
        <div className={styles['create-order-page__body__main']}>
          <div className={styles['create-order-page__body__main__content']}>
            <div className={styles['create-order-page__body__main__content__wrapper']}>
              <WizardPicto
                options={stepsPicto}
                className={styles['create-order-page__body__main__content__wrapper__wizard']}
              />
            </div>
            {selectedStep === 0 && <PatientFilesForm nextCallback={navigateNextStep} />}
            {selectedStep === 1 && <ProthesesForm nextCallback={navigateNextStep} />}
            {selectedStep === 2 && <DesignStep nextCallback={navigateNextStep} />}
            {selectedStep === 3 && <ExportStep />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOrderPage;
