enum CursorEnum {
  POINTER = 'pointer',
  NOT_ALLOWED = 'not-allowed'
}

const teethPositions: number[] = [
  18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 48, 47, 46, 45, 44, 43, 42, 41,
  31, 32, 33, 34, 35, 36, 37, 38
];

export const cursors: { [key: number]: string } = {
  18: CursorEnum.POINTER,
  28: CursorEnum.POINTER,
  38: CursorEnum.POINTER,
  48: CursorEnum.POINTER,
  17: CursorEnum.POINTER,
  16: CursorEnum.POINTER,
  15: CursorEnum.POINTER,
  14: CursorEnum.POINTER,
  13: CursorEnum.POINTER,
  12: CursorEnum.POINTER,
  11: CursorEnum.POINTER,
  21: CursorEnum.POINTER,
  22: CursorEnum.POINTER,
  23: CursorEnum.POINTER,
  24: CursorEnum.POINTER,
  25: CursorEnum.POINTER,
  26: CursorEnum.POINTER,
  27: CursorEnum.POINTER,
  47: CursorEnum.POINTER,
  46: CursorEnum.POINTER,
  45: CursorEnum.POINTER,
  44: CursorEnum.POINTER,
  43: CursorEnum.POINTER,
  42: CursorEnum.POINTER,
  41: CursorEnum.POINTER,
  31: CursorEnum.POINTER,
  32: CursorEnum.POINTER,
  33: CursorEnum.POINTER,
  34: CursorEnum.POINTER,
  35: CursorEnum.POINTER,
  36: CursorEnum.POINTER,
  37: CursorEnum.POINTER
};

export const getMissingTeeth = (tooth: number | undefined): Array<number> => {
  const selectedToothIndex = teethPositions.indexOf(tooth as number);
  if (tooth) {
    return teethPositions.filter(
      (position, index) =>
        position && (index > selectedToothIndex + 1 || index < selectedToothIndex - 1)
    );
  }
  return [];
};
