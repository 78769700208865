import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FilesList,
  FileUpload,
  Text,
  Viewer
} from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Order, OrderFile, OrderForCreation, OrderItem } from '../../../../models/order';
import { orderFilesSelector } from '../../../../store/orders/orders.selectors';
import { ordersActions } from '../../../../store/orders/orders.reducer';
import styles from './patient-files.module.scss';
import useForm from '../../../../utils/useForm';
import {
  checkEmptyFile,
  checkFileAlreadyUploaded,
  checkFileExtensionAllowed,
  getTextureFile,
  isThumbprintExtension,
  mapFileToOrderFile
} from '../../../../utils/file.utils';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import { ToastType } from '../../../../enum/feedback';
import { useGetCommonTypesQuery } from '../../../../services/common-types-api.services';
import {
  useCreateOrderItemMutation,
  useCreateOrderMutation,
  useCreatePatientFileMutation,
  usePredictPatientFileLabelMutation,
  useUploadToStorageMutation
} from '../../../../services/orders-api.services';
import { FileLabelEnum } from '../../../../enum/file-label';
import { ErrorCode } from '../../../../enum/error.ts';
import { FileLabel } from '../../../../models/common-types.tsx';
import { ColorPropsEnum } from '../../../../enum/color.ts';
import { useGetConnectedUserQuery } from '../../../../services/user.api.services.ts';
import { getMessageError } from '../../../../utils/utils.tsx';

type Props = {
  nextCallback: () => void;
};
const PatientFilesForm = ({ nextCallback }: Props) => {
  const { t } = useTranslation(['order']);
  const orderFiles = useAppSelector(orderFilesSelector);
  const dispatch = useAppDispatch();
  const [errorFiles, setErrorFiles] = useState<string>('');
  const [errorLabels, setErrorLabels] = useState<string>('');
  const [file3dToDisplay, setFile3dToDisplay] = useState<OrderFile>();
  const [fileTextureToDisplay, setFileTextureToDisplay] = useState<OrderFile>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const [uploadedFilesNumber, setUploadedFilesNumber] = useState<number>(0);

  const { data: connectedUser } = useGetConnectedUserQuery();
  const { data: commonTypes } = useGetCommonTypesQuery();
  const [predictLabel] = usePredictPatientFileLabelMutation();
  const [createOrder] = useCreateOrderMutation();
  const [createOrderItem] = useCreateOrderItemMutation();

  const [createPatientFile] = useCreatePatientFileMutation();
  const [uploadToStorage] = useUploadToStorageMutation();

  useEffect(() => {
    if (orderFiles?.length > 0 && uploadedFilesNumber === orderFiles?.length) {
      setIsSaving(false);
      nextCallback();
    }
  }, [uploadedFilesNumber]);

  const deleteFile = async (fileToDelete: OrderFile): Promise<void> => {
    const orderFilesCopy: OrderFile[] = Object.assign([], orderFiles);
    const fileIndex = orderFilesCopy.findIndex((file) => file.fileName === fileToDelete.fileName);
    if (fileIndex > -1) {
      orderFilesCopy.splice(fileIndex, 1);
      dispatch(ordersActions.setFiles(orderFilesCopy));
    }
    await displayFile(orderFilesCopy, orderFilesCopy[orderFilesCopy.length - 1]);
  };

  const resetErrors = (): void => {
    setErrorFiles('');
    setErrorLabels('');
  };

  const uploadFiles = async (newFilesToUpload: File[]): Promise<void> => {
    setIsLoadingFile(true);
    resetErrors();
    const orderFilesCopy: OrderFile[] = Object.assign([], orderFiles);
    const newFilesMap: OrderFile[] = [];

    // update new files with loading true
    for (const newFileToUpload of newFilesToUpload) {
      const orderFile = mapFileToOrderFile(newFileToUpload, true);
      newFilesMap.push(orderFile);

      if (!checkFile(orderFile)) {
        setIsLoadingFile(false);
        return;
      }
    }
    dispatch(ordersActions.setFiles([...orderFiles, ...newFilesMap]));

    // Add label and display in viewer
    for (const newFileToUpload of newFilesToUpload) {
      const orderFile = mapFileToOrderFile(newFileToUpload, false);
      const prediction = await predictLabel({
        fileName: `${orderFile.fileName}.${orderFile.extension}`
      });
      if ('data' in prediction && fileLabelsAllowed.includes(prediction.data as FileLabelEnum)) {
        orderFile.fileLabel = prediction.data;
      }

      orderFilesCopy.push(orderFile);
    }
    dispatch(ordersActions.setFiles(orderFilesCopy));
    await displayFile(orderFilesCopy, orderFilesCopy[orderFilesCopy.length - 1]);
    setIsLoadingFile(false);
  };

  const displayFile = async (orderFiles: OrderFile[], fileToDisplay: OrderFile): Promise<void> => {
    const files3D = orderFiles.filter(
      (file) => isThumbprintExtension(file.extension) && file === fileToDisplay
    );
    const file3D = files3D?.length ? files3D[0] : undefined;
    setFile3dToDisplay(file3D);
    const fileTexture = await getTextureFile(orderFiles, file3D);
    setFileTextureToDisplay(fileTexture);
  };

  const submit = async (): Promise<void> => {
    resetErrors();
    const upperFileLength = orderFiles.filter(
      (file) => file.fileLabel === FileLabelEnum.UPPER
    )?.length;
    const lowerFileLength = orderFiles.filter(
      (file) => file.fileLabel === FileLabelEnum.LOWER
    )?.length;
    const fileLabelNotAllowedLength = orderFiles.filter(
      (file) => !file.fileLabel || !fileLabelsAllowed.includes(file.fileLabel)
    )?.length;
    if (!orderFiles?.length || !upperFileLength || !lowerFileLength) {
      setErrorFiles(ErrorCode.ORDERS_FILE_MANDATORY);
      return;
    } else {
      let error: string | undefined;
      if (fileLabelNotAllowedLength) {
        error = ErrorCode.ORDERS_FILE_LABEL_MISSING;
      } else if (upperFileLength > 1) {
        error = ErrorCode.ORDERS_FILE_LABEL_UPPER;
      } else if (lowerFileLength > 1) {
        error = ErrorCode.ORDERS_FILE_LABEL_LOWER;
      }
      if (error) {
        setErrorLabels(error);
        return;
      }
    }
    setIsSaving(true);
    await saveOrder();
  };

  const saveOrder = async (): Promise<void> => {
    const order: OrderForCreation = {
      dentistName: `${connectedUser?.firstName} ${connectedUser?.lastName}`,
      dentistEmail: `${connectedUser?.email}`,
      clinicName: `${connectedUser?.clinic?.name}`,
      clinicId: +`${connectedUser?.clinic?.id}`,
      toManufacture: true,
      patient: { reference: 'REF' },
      tags: ['Circle Dentist'],
      labId: +`${connectedUser?.laboratory?.id}`,
      labName: `${connectedUser?.laboratory?.name}`,
      expectedDate: new Date(),
      instructions: ''
    };
    await createOrder(order)
      .unwrap()
      .then(async (order) => {
        await createOrderItem({
          orderNumber: order.orderNumber,
          item: {
            product: {
              id: import.meta.env.VITE_CROWN_PRODUCT_ID as unknown as number
            },
            tags: []
          }
        })
          .unwrap()
          .then(async (orderItem: OrderItem) => {
            const orderWithItem: Order = { ...order, items: [orderItem] };
            dispatch(ordersActions.setOrder(orderWithItem));
            await uploadFilesToOrder(order.orderNumber);
          });
      })
      .catch((error) => {
        dispatch(
          feedbackActions.setToast({
            message: getMessageError(error),
            type: ToastType.DANGER
          })
        );
        setIsSaving(false);
      });
  };

  const uploadFilesToOrder = async (orderNumber: string): Promise<void> => {
    if (orderNumber && orderFiles?.length) {
      let uploadedFilesCount: number = 0;
      for (const newFileToUpload of orderFiles) {
        if (newFileToUpload.fileLabel) {
          await createPatientFile({
            orderNumber,
            file: {
              fileName: newFileToUpload.fileName,
              extension: newFileToUpload.extension,
              mimeType: newFileToUpload.mimeType,
              fileLabel: newFileToUpload.fileLabel
            }
          })
            .unwrap()
            .then((result) => {
              uploadToStorage({
                url: result.uploadUrl,
                file: newFileToUpload.data
              });
              uploadToStorage({
                url: result.uploadUrlS3,
                file: newFileToUpload.data
              })
                .unwrap()
                .then(() => {
                  uploadedFilesCount = uploadedFilesCount + 1;
                  setUploadedFilesNumber(uploadedFilesCount);
                });
            })
            .catch((error) => {
              dispatch(
                feedbackActions.setToast({
                  message: getMessageError(error),
                  type: ToastType.DANGER
                })
              );
              setIsSaving(false);
            });
        }
      }
    }
  };

  const { handleSubmit } = useForm({}, submit);

  const checkFile = (file: OrderFile): boolean => {
    if (!checkEmptyFile(file.data)) {
      dispatch(
        feedbackActions.setToast({
          message: t(ErrorCode.ORDERS_FILE_EMPTY, { ns: 'error' }),
          type: ToastType.DANGER
        })
      );
      return false;
    }
    if (!checkFileAlreadyUploaded(file.fileName, orderFiles)) {
      dispatch(
        feedbackActions.setToast({
          message: t(ErrorCode.ORDERS_FILE_ALREADY_EXISTS, { ns: 'error' }),
          type: ToastType.DANGER
        })
      );
      return false;
    }
    if (!checkFileExtensionAllowed(file.extension)) {
      dispatch(
        feedbackActions.setToast({
          message: t(ErrorCode.ORDERS_FILE_NOT_ALLOWED, { ns: 'error', extension: file.extension }),
          type: ToastType.DANGER
        })
      );
      return false;
    }
    return true;
  };

  const fileLabelsAllowed: Array<string> = Object.values([
    FileLabelEnum.UPPER,
    FileLabelEnum.LOWER,
    FileLabelEnum.OCCLUSION_KEY,
    FileLabelEnum.TEXTURE,
    FileLabelEnum.UPPER_PRESCAN,
    FileLabelEnum.LOWER_PRESCAN
  ]);

  const labels = commonTypes?.fileLabels
    ?.filter((fileLabel: FileLabel) => fileLabelsAllowed.includes(fileLabel.code))
    ?.map((fileLabel: FileLabel) => {
      return {
        label: t(`fileUpload.labels.${fileLabel.code}`),
        value: fileLabel.code,
        image: fileLabel.imageUrl
      };
    });

  const handleLabelChangeValue = (file: OrderFile, newValue: string): void => {
    resetErrors();
    const copy = orderFiles.map((orderFile) =>
      orderFile.fileName === file.fileName ? { ...orderFile, fileLabel: newValue } : orderFile
    ) as OrderFile[];

    dispatch(ordersActions.setFiles(copy));
  };

  const labelerConfig = {
    coverText: t('fileUpload.labelerCover'),
    isLoading: false,
    onChange: (file: OrderFile, label: string) => handleLabelChangeValue(file, label),
    maxHeight: 350
  };

  return (
    <div className={styles['patient-files__box']}>
      <form onSubmit={handleSubmit} className={styles['patient-files__box__form']}>
        <Box color="white" className={styles['patient-files__box__form__box-file']}>
          <div className={styles['patient-files__box__form__content']}>
            <div className={styles['patient-files__box__form__content__files']}>
              <FileUpload
                title={t('fileUpload.uploadBox')}
                onUploadFile={(newFiles: File[]) => uploadFiles(newFiles)}
                className={
                  errorFiles
                    ? styles['patient-files__box__form__content__error']
                    : styles['patient-files__box__form__content__files__upload']
                }
              />
              {(errorFiles || errorLabels) && (
                <Text
                  label={t(`${errorFiles}${errorLabels}`, { ns: 'error' })}
                  color={ColorPropsEnum.DANGER}
                  className={styles['patient-files__box__form__content__error-text']}
                />
              )}
              <FilesList
                files={orderFiles}
                readonly={false}
                labelsList={labels}
                labelerProps={{ ...labelerConfig, variant: errorLabels ? 'danger' : 'default' }}
                fileActions={(file: OrderFile) => [
                  [
                    {
                      id: 'delete',
                      disabled: false,
                      label: t('action.delete', { ns: 'common' }),
                      type: 'button',
                      onClick: () => {
                        deleteFile(file);
                      }
                    }
                  ]
                ]}
                onClickCallback={(selectedFile: OrderFile) => displayFile(orderFiles, selectedFile)}
                initialSelectedIndex={orderFiles?.length - 1}
                className={styles['patient-files__box__form__content__files__list']}
              />
            </div>
            <div className={styles['patient-files__box__form__content__aside']}>
              <Box
                color={ColorPropsEnum.GREY}
                className={styles['patient-files__box__form__content__aside__viewer']}>
                <Viewer
                  noFileLabel={t('createOrder.no-file')}
                  file3D={file3dToDisplay}
                  fileTexture={fileTextureToDisplay}
                  isLoading={isLoadingFile}
                  className={styles['patient-files__box__form__content__aside__viewer__content']}
                />
              </Box>
            </div>
          </div>
        </Box>
        <div className="form__submit-button form__submit-button--right">
          <Button
            label={t('action.next', { ns: 'common' })}
            type="submit"
            category="primary"
            variant="success"
            iconRight="fa-chevron-right"
            isLoading={isSaving}
          />
        </div>
      </form>
    </div>
  );
};
export default PatientFilesForm;
