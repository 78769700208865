import { api } from './api';
import {
  Order,
  OrderFile,
  OrderFileToUpload,
  OrderForCreation,
  OrderItem,
  OrderItemForCreation,
  ValidateOrder
} from '../models/order';
import { FileLabelEnum } from '../enum/file-label';

export const ordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOneOrder: builder.query<Order, string>({
      query: (id) => ({
        url: `/orders/${id}`,
        method: 'GET'
      }),
      providesTags: ['OneOrder']
    }),
    createOrder: builder.mutation<Order, OrderForCreation>({
      query: (orderToCreate) => ({
        url: `/orders`,
        method: 'POST',
        body: orderToCreate
      })
    }),
    createOrderItem: builder.mutation<
      OrderItem,
      { orderNumber: string; item: OrderItemForCreation }
    >({
      query: (arg) => {
        const { orderNumber, item } = arg;
        return {
          url: `/orders/${orderNumber}/items`,
          method: 'POST',
          body: item
        };
      }
    }),
    patchOrderItem: builder.mutation<
      OrderItem,
      { orderNumber: string; id?: number; item: OrderItemForCreation }
    >({
      query: (arg) => {
        const { orderNumber, id, item } = arg;
        return {
          url: `/orders/${orderNumber}/items/${id}`,
          method: 'PATCH',
          body: item
        };
      }
    }),
    patchOrder: builder.mutation<Order, Partial<Order>>({
      query: ({ orderNumber, ...patch }) => ({
        url: `/orders/${orderNumber}`,
        method: 'PATCH',
        body: patch
      })
    }),
    validateOrder: builder.mutation<Order, ValidateOrder>({
      query: ({ orderNumber, ...validateOrderBody }) => ({
        url: `/orders/${orderNumber}/validate`,
        method: 'POST',
        body: validateOrderBody
      })
    }),
    submitOrder: builder.mutation<Order, string>({
      query: (orderNumber) => ({
        url: `/orders/${orderNumber}/submit`,
        method: 'POST'
      })
    }),
    createPatientFile: builder.mutation<
      { uploadUrl: string; uploadUrlS3: string },
      { orderNumber: string; file: OrderFileToUpload }
    >({
      query: ({ orderNumber, file }) => ({
        url: `/orders/${orderNumber}/patient-files`,
        method: 'POST',
        body: file
      }),
      invalidatesTags: ['OneOrder']
    }),
    deleteOrderPatientFile: builder.mutation<void, { orderNumber: string; file: OrderFile }>({
      query: ({ orderNumber, file }) => ({
        url: `/orders/${orderNumber}/patient-files/${file.id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['OneOrder']
    }),
    uploadToStorage: builder.mutation<object, { url: string; file: File }>({
      query: ({ url, file }) => ({
        url: url,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        body: file
      })
    }),
    predictPatientFileLabel: builder.mutation<FileLabelEnum, { fileName: string }>({
      query: ({ fileName }) => ({
        url: `/labels/predict`,
        method: 'POST',
        params: { filename: fileName },
        body: {},
        responseHandler: 'text'
      })
    }),
    labelOrderPatientFile: builder.mutation<OrderFile, { orderNumber: string; file: OrderFile }>({
      query: ({ orderNumber, file }) => ({
        url: `/orders/${orderNumber}/patient-files/${file.id}`,
        method: 'PATCH',
        body: { fileLabel: file.fileLabel }
      }),
      invalidatesTags: ['OneOrder'] // invalidate patient files
    })
  })
});

export const {
  useDeleteOrderPatientFileMutation,
  useGetOneOrderQuery,
  useLazyGetOneOrderQuery,
  useCreateOrderMutation,
  useCreateOrderItemMutation,
  usePatchOrderItemMutation,
  usePatchOrderMutation,
  useValidateOrderMutation,
  useSubmitOrderMutation,
  useCreatePatientFileMutation,
  useUploadToStorageMutation,
  useLabelOrderPatientFileMutation,
  usePredictPatientFileLabelMutation
} = ordersApi;
