import { useAppSelector } from '../../../../store/hooks';
import { orderSelector } from '../../../../store/orders/orders.selectors';
import StreamFeature from './StreamFeature';
import { useEffect, useState } from 'react';
import { useGetOneManufacturingOrderQuery } from '../../../../services/manufacturing-orders-api.services.tsx';
import { WorkflowModelingStepEnum } from '../../../../enum/workflow-step.ts';
import { history } from '../../../../models/history.tsx';
import { useNavigate } from 'react-router-dom';
import { useGetOneOrderQuery } from '../../../../services/orders-api.services.ts';
import { StreamingParams } from '../../../../models/streaming.tsx';

type Props = {
  nextCallback: () => void;
};
const DesignStep = ({ nextCallback }: Props) => {
  const orderSelect = useAppSelector(orderSelector);
  history.navigate = useNavigate();

  const [streamingParams, setStreamingParams] = useState<StreamingParams | undefined>(undefined);
  // at first, we query ms-orders and skip ms-manufacturing
  const [skipOrderQuery, setSkipOrderQuery] = useState<boolean>(false);
  const [skipManufacturingOrderQuery, setSkipManufacturingOrderQuery] = useState<boolean>(true);

  // we query ms-order => until we reach validation step => then allow query on manufacturing order and skip order
  const { data: order } = useGetOneOrderQuery(orderSelect?.orderNumber, {
    pollingInterval: 3000,
    skip: skipOrderQuery
  });
  const { data: manufacturingOrder } = useGetOneManufacturingOrderQuery(orderSelect?.orderNumber, {
    pollingInterval: 2000,
    skip: skipManufacturingOrderQuery
  });

  useEffect(() => {
    if (manufacturingOrder?.streamingParameters) {
      setStreamingParams(JSON.parse(manufacturingOrder.streamingParameters));
      setSkipManufacturingOrderQuery(true);
    }
  }, [manufacturingOrder?.streamingParameters]);

  if (
    order?.currentStep &&
    [WorkflowModelingStepEnum.MODELING, WorkflowModelingStepEnum.MODELING_DESIGN].includes(
      order.currentStep as WorkflowModelingStepEnum
    )
  ) {
    setSkipOrderQuery(true);
    setSkipManufacturingOrderQuery(false);
  }

  const closeStreaming = (): void => {
    nextCallback();
  };

  return <StreamFeature streamingParams={streamingParams} onCloseStreaming={closeStreaming} />;
};

export default DesignStep;
