import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const initFirebase = () => {
  const defaultConfig: object = {
    apiKey: 'XXXXXX',
    authDomain: 'XXXXXX',
    projectId: 'XXXXX',
    storageBucket: 'XXXXXX',
    messagingSenderId: 'XXXXXXX'
  };

  const config: object =
    typeof import.meta.env.VITE_FIREBASE_CONFIG !== 'undefined'
      ? JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG)
      : defaultConfig;

  const firebaseConfig = { ...config, ...{ appId: import.meta.env.VITE_FIREBASE_CONFIG_API_ID } };
  // Initialize Firebase
  return initializeApp(firebaseConfig);
};

const getAuthFirebase = () => {
  const app = initFirebase();
  return getAuth(app);
};

export default getAuthFirebase;
