import { OrdersReducer } from '../../models/order';

interface ReducerState {
  ordersState: OrdersReducer;
}

export const orderSelector = (state: ReducerState) => {
  return state?.ordersState?.order;
};

export const orderFilesSelector = (state: ReducerState) => {
  return state?.ordersState?.files;
};

export const orderErrorSelector = (state: ReducerState) => {
  return state?.ordersState?.error;
};

export const isOrderStreamingSelector = (state: ReducerState) => {
  return state?.ordersState?.isStreaming;
};
