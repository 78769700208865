import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewUser, UsersReducer } from '../../models/user';
import { DentalNotation, DentistRole, Language } from '../../enum/user.ts';

const initialUsers: UsersReducer = {
  newUser: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    language: Language.fr_FR,
    dentalNotation: DentalNotation.ISO,
    role: DentistRole.DENTIST,
    email: '',
    laboratory: {},
    clinic: {},
    isAllowedToOrder: false
  }
};

const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsers,
  reducers: {
    setUser: (state, action: PayloadAction<NewUser>) => {
      state.newUser = action.payload;
    },
    resetUser: (state) => {
      state.newUser = initialUsers.newUser;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<UsersReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialUsers };
    });
  }
});

const usersActions = usersSlice.actions;

export { usersSlice, usersActions };
