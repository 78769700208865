import axios, { AxiosResponse } from 'axios';

/**
 * Add additional data in firebase token with MS-Users.
 * This data must be only concerned security and access control.
 *
 * @param email
 * @param idToken
 *
 * @return Promise<axios.AxiosResponse<string>>
 */
export const setClaims = (email: string, idToken: string): Promise<AxiosResponse<string>> => {
  const url = `/users/${email}/claims`;
  return axios.post(url, { idToken });
};

/**
 * Check if the user have enough rights and the correct role to identify himself on Firebase.
 * @param email
 */
export const checkUserAccess = (email: string) => {
  const url = `/users/${email}/check`;
  return axios.post(url);
};
