import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Order, OrderFile, OrdersReducer } from '../../models/order';

const initialOrder: OrdersReducer = {
  order: {
    id: 0,
    orderNumber: '',
    dentistName: '',
    dentistEmail: '',
    clinicName: '',
    clinicId: 0,
    patient: { id: 0, reference: '', diagnostic: { id: 0 } },
    labId: 0,
    labName: '',
    items: []
  },
  files: [],
  error: '',
  isStreaming: false
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrder,
  reducers: {
    setOrder: (state, action: PayloadAction<Order>) => {
      state.order = action.payload;
    },
    setFiles: (state, action: PayloadAction<OrderFile[]>) => {
      state.files = action.payload;
    },
    setIsStreaming: (state, action: PayloadAction<boolean>) => {
      state.isStreaming = action.payload;
    },
    resetOrder: () => initialOrder
  },
  extraReducers: (builder: ActionReducerMapBuilder<OrdersReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialOrder };
    });
  }
});

export const ordersActions = ordersSlice.actions;
