import { api } from './api';
import { CommonTypes } from '../models/common-types';

export const commonTypesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCommonTypes: builder.query<CommonTypes, void>({
      query: () => {
        return `/common-types`;
      }
    })
  })
});

export const { useGetCommonTypesQuery } = commonTypesApi;
