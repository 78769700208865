import { FeedbackReducer } from '../../models/feedback';

interface ReducerState {
  feedbackState: FeedbackReducer;
}

const fieldErrorSelector = (state: ReducerState) => {
  return state.feedbackState.fieldError;
};

const toastSelector = (state: ReducerState) => {
  return state.feedbackState.toast;
};

const attentionBoxErrorSelector = (state: ReducerState) => {
  return state.feedbackState.attentionBoxError;
};

export { fieldErrorSelector, toastSelector, attentionBoxErrorSelector };
