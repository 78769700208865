import { configureStore, createAction, Reducer, UnknownAction } from '@reduxjs/toolkit';
import { AuthenticationReducer, UsersReducer } from '../models/user';
import { authSlice } from './auth/auth.reducers';
import { feedbackSlice } from './feedback/feedback.reducer';
import { FeedbackReducer } from '../models/feedback';
import { api } from '../services/api';
import { rtkQueryErrorLogger } from '../services/errors.services.tsx';
import { usersSlice } from './users/users.reducer.tsx';
import { OrdersReducer } from '../models/order.tsx';
import { ordersSlice } from './orders/orders.reducer.tsx';

interface ReducerInterface {
  authenticationState: Reducer<AuthenticationReducer, UnknownAction>;
  feedbackState: Reducer<FeedbackReducer, UnknownAction>;
  userState: Reducer<UsersReducer>;
  ordersState: Reducer<OrdersReducer>;
}

const reducer: ReducerInterface = {
  authenticationState: authSlice.reducer,
  feedbackState: feedbackSlice.reducer,
  userState: usersSlice.reducer,
  ordersState: ordersSlice.reducer
};

const store = configureStore({
  reducer: {
    ...reducer,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(api.middleware)
      .concat(rtkQueryErrorLogger)
});

export const resetAll = createAction('RESET_ALL');

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
