import { api } from './api';

export const filesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    downloadFileFromStorage: builder.mutation<BlobPart, { url: string }>({
      query: (arg) => {
        const { url } = arg;
        return {
          url,
          method: 'GET',
          responseHandler: async (response) => await response.blob()
        };
      }
    })
  })
});

export const { useDownloadFileFromStorageMutation } = filesApi;
