import i18next from 'i18next';
import { ErrorCode } from '../enum/error';
import { Country } from '../enum/user';
import { forceRefreshToken } from '../services/firebase.services.tsx';
import { Dispatch } from 'react';
import { feedbackActions } from '../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../enum/feedback.ts';

/* We specify this rule because we don't know the payload type, and we use explicitly error as object after.*/
/* So this is an emergency case, don't reproduce */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getMessageError = (error: any): string => {
  let message = i18next.t('error.generic', { ns: 'common' });
  if ('data' in error) {
    if (typeof error.data === 'object') {
      // Handle error from our backend
      if (Object.values(ErrorCode).includes(error.data.error)) {
        message = i18next.t(error.data.error, { ns: 'error' });
      } else if (error.status !== 500) {
        message = error.data.message;
      }
    }
  }

  return message;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export const countries = [
  Object.values(Country).map((country) => {
    return {
      label: i18next.t(`countries.${country.toLowerCase()}`, { ns: 'common' }),
      value: country
    };
  })
];

export const viteMode = (): string => {
  let mode = '';
  if (import.meta.env.MODE === 'development' && import.meta.env.VITE_GOOGLE_CLOUD_DEV) {
    mode = 'dev';
  } else if (import.meta.env.MODE === 'development') {
    mode = 'local';
  }
  return mode;
};

export const isOrdersApi = (urlStartPart: string): boolean => {
  return ['orders', 'common-types', 'components', 'products', 'labs'].includes(urlStartPart);
};

export const isUsersApi = (urlStartPart: string): boolean => {
  return ['users', 'establishments'].includes(urlStartPart);
};

export const isManuApi = (urlStartPart: string): boolean => {
  return ['manufacturing'].includes(urlStartPart);
};

export const checkTokenValidity = (dispatch: Dispatch<any>) => {
  /**
   * Check if the current token is currently valid.
   * Otherwise, redirect the user to the login page.
   */
  forceRefreshToken().catch((err) => {
    if (err?.message.includes('auth/user-token-expired')) {
      dispatch(
        feedbackActions.setToast({
          message: i18next.t('auth/user-token-expired', { ns: 'error' }),
          type: ToastType.DANGER
        })
      );
    } else {
      dispatch(feedbackActions.setToast({ message: err.message, type: ToastType.DANGER }));
    }
  });
};
