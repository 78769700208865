import { api } from './api';
import { NewUser, UserInfos, UserListResponse } from '../models/user';
import { mapUser, mapUsers } from '../utils/user.utils';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addUser: builder.mutation<UserInfos, NewUser>({
      query: (payload) => ({
        url: '/users',
        method: 'POST',
        body: payload
      })
    }),
    getConnectedUser: builder.query<UserInfos, void>({
      query: () => ({
        url: `/users/me`,
        method: 'GET'
      }),
      transformResponse: (response: UserInfos) => {
        return mapUser(response);
      },
      providesTags: ['UserInfo']
    }),
    patchDentist: builder.mutation<UserInfos, Partial<UserInfos>>({
      query: ({ email, ...patch }) => ({
        url: `/users/${email}`,
        method: 'PATCH',
        body: patch
      })
    }),
    getUsers: builder.query({
      query: (arg) => {
        const { page, limit, filters, sort } = arg;
        return `/users?page=${page}&limit=${limit}${filters}${sort}`;
      },
      transformResponse: (response: UserListResponse) => {
        return { data: mapUsers(response.data), count: response.meta.totalItems };
      }
    }),
    deactivateUser: builder.mutation<UserInfos, string>({
      query: (email) => ({
        url: `/users/${email}`,
        method: 'DELETE'
      })
    }),
    resetPassword: builder.mutation<UserInfos, string>({
      query: (email) => ({
        url: `/users/${email}/reset-password`,
        method: 'POST'
      })
    })
  })
});

export const {
  useGetConnectedUserQuery,
  usePatchDentistMutation,
  useLazyGetUsersQuery,
  useResetPasswordMutation,
  useAddUserMutation,
  useDeactivateUserMutation
} = userApi;
