import { FileLabelEnum } from '../enum/file-label';
import { OrderFile } from '../models/order';

const allowedThumbprintExtensions = ['stl', 'ply', 'obj'];
const allowedImageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'heic', 'heif', 'mtl', 'xls', 'xlsx'];
const allowedVideoExtensions = [
  'mov',
  'mp4',
  'avi',
  'wmf',
  'flv',
  'webm',
  'mpg',
  'mpa',
  'vob',
  'mpeg',
  'h264',
  'h265'
];

export const checkEmptyFile = (file: File): boolean => {
  return file?.size > 0;
};

export const checkFileAlreadyUploaded = (fileName: string, orderFiles: OrderFile[]): boolean => {
  return orderFiles.findIndex((file) => file.fileName === fileName) === -1;
};

export const isThumbprintExtension = (extension: string) =>
  allowedThumbprintExtensions.includes(extension?.toLowerCase());

export const checkFileExtensionAllowed = (extension: string): boolean => {
  return (
    isThumbprintExtension(extension) ||
    allowedVideoExtensions.includes(extension?.toLowerCase()) ||
    allowedImageExtensions.includes(extension?.toLowerCase())
  );
};

export const mapFileToOrderFile = (
  newFileToUpload: File,
  isLoading = true,
  fileLabel?: FileLabelEnum
) => {
  const extension = newFileToUpload.name.substring(newFileToUpload.name.lastIndexOf('.') + 1);
  const fileName = newFileToUpload.name.substring(0, newFileToUpload.name.lastIndexOf('.'));
  const newFile: OrderFile = {
    extension: extension,
    fileLabel: fileLabel,
    fileName: fileName,
    mimeType: 'application/octet-stream',
    isLoading: isLoading,
    data: newFileToUpload
  };
  return newFile;
};

export const getTextureFile = async (
  orderFiles: OrderFile[],
  file3D?: OrderFile
): Promise<OrderFile | undefined> => {
  if (file3D) {
    // Header attribute value to describe the texture file name in 3D file
    const textureFileHeader = 'comment TextureFile ';

    const header = await getFile3dHeader(file3D);
    if (header.includes(textureFileHeader)) {
      let textureFileName = header.substring(
        header.indexOf(textureFileHeader) + textureFileHeader.length
      );
      // Get texture file name in the file3D header
      textureFileName = textureFileName.substring(0, textureFileName.indexOf('\n'));
      // Search if the texture file has been uploaded
      const textureFile = orderFiles.filter(
        (file) => `${file.fileName}.${file.extension}` === textureFileName
      );
      if (textureFile?.length) {
        return textureFile[0];
      }
    }
  }
};
const getFile3dHeader = async (file3D: OrderFile): Promise<string> => {
  const response = await fetch(URL.createObjectURL(file3D.data));
  const data: string = await response.text();
  // Return only the header of the file
  return data.substring(0, data.indexOf('end_header'));
};
