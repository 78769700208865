import { UserInfos } from '../models/user';
import { capitalizeFirstLetter } from './utils';

export const mapUsers = (users: UserInfos[]): UserInfos[] => {
  return users?.map((user: UserInfos) => mapUser(user));
};

export const mapUser = (user: UserInfos): UserInfos => {
  return {
    ...user,
    firstName: capitalizeFirstLetter(user.firstName),
    lastName: capitalizeFirstLetter(user.lastName)
  };
};
