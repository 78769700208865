import {
  Avatar,
  Chips,
  DropdownMenu,
  Header,
  IconButton,
  Toast
} from '@platform-storybook/circlestorybook';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { history } from '../../models/history.tsx';
import { useTranslation } from 'react-i18next';
import './private-app.scss';
import { toastSelector } from '../../store/feedback/feedback.selector';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { api } from '../../services/api.ts';
import { useEffect, useState } from 'react';
import { resetAll } from '../../store';
import { ColorPropsEnum } from '../../enum/color.ts';
import { authActions } from '../../store/auth/auth.reducers.tsx';
import { connectedUserSelector } from '../../store/auth/auth.selectors.tsx';
import { viteMode } from '../../utils/utils.tsx';
import { isOrderStreamingSelector, orderSelector } from '../../store/orders/orders.selectors.tsx';
import { useEndTaskMutation } from '../../services/manufacturing-orders-api.services.tsx';

const PrivateApp = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  history.navigate = useNavigate();

  const toast = useAppSelector(toastSelector);
  const connectedUser = useAppSelector(connectedUserSelector);
  const [isResetStore, setIsResetStore] = useState<boolean>(false);
  const envLabel = viteMode();
  const isStreaming = useAppSelector(isOrderStreamingSelector);
  const order = useAppSelector(orderSelector);
  const [endTask] = useEndTaskMutation();

  const handleLogoClick = async () => {
    if (isStreaming) {
      await endTask(order.orderNumber);
    }
    if (history.navigate) history.navigate('/');
  };

  useEffect(() => {
    dispatch(resetAll());
    dispatch(api.util.resetApiState());
    setIsResetStore(true);
  }, []);

  const getAvatarLetter = () => {
    return connectedUser?.email?.substring(0, 1).toUpperCase();
  };

  const contentAvatarMenu = [
    [{ label: t('link.myProfile'), type: 'nav', link: '/profile', disabled: true }],
    [
      {
        label: t('link.logout'),
        type: 'button',
        onClick: async () => {
          if (isStreaming) {
            await endTask(order.orderNumber);
          }
          dispatch(authActions.logout());
        }
      }
    ]
  ];

  const contentCandyMenu = [
    [
      {
        label: t('appMenu.circleAppDentist'),
        type: 'nav',
        link: 'https://alpha.circle.dental',
        target: '_blank'
      },
      {
        label: t('appMenu.circleAppLab'),
        type: 'nav',
        link: 'https://dev.lab.circle.dental',
        target: '_blank'
      }
    ]
  ];

  return (
    isResetStore && (
      <div className="private-app" data-cy="privateApp">
        {toast && (
          <Toast
            message={'message' in toast ? toast.message : t('generic', { ns: 'error' })}
            onClose={() => {
              dispatch(feedbackActions.resetToast());
            }}
            autoHideDuration={toast.type === 'danger' ? 8000 : 5000}
            type={toast.type}
          />
        )}

        <Header
          logoClass="logo--circle-one"
          color="grey"
          className="private-app__header__title"
          onLogoClick={() => handleLogoClick()}>
          <>
            <div className="private-app__header__title__env">
              {envLabel && <Chips firstLabel={envLabel} color={ColorPropsEnum.PRIMARY} />}
            </div>
            <nav className="private-app__header__nav">
              <DropdownMenu
                renderTargetButton={(props: { active: boolean }) => (
                  <IconButton
                    className="private-app__header__nav__icon-btn"
                    faIconClass="ellipsis-vertical"
                    mode="candy-menu"
                    isActive={props.active}
                    radius="full"
                    color={ColorPropsEnum.PRIMARY}
                  />
                )}
                data={contentCandyMenu}
              />
              <DropdownMenu
                renderTargetButton={(props: { active: boolean }) => (
                  <Avatar
                    label={getAvatarLetter() || ''}
                    isActive={props.active}
                    cursorPointer={true}
                    className="private-app__header__nav__avatar"
                  />
                )}
                data={contentAvatarMenu}
              />
            </nav>
          </>
        </Header>

        <main className="private-app__main">
          <Outlet />
        </main>
      </div>
    )
  );
};
export default PrivateApp;
