import { NavigateFunction } from 'react-router';
import { Location } from '@remix-run/router';

// custom history object to allow navigation outside react components
interface History {
  navigate: NavigateFunction | undefined;
  location: Location | undefined;
}
export const history: History = {
  navigate: undefined,
  location: undefined
};
