import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from './store/hooks';
import { isAuthenticatedSelector } from './store/auth/auth.selectors.tsx';
import { isCurrentUser } from './services/firebase.services.tsx';
import PublicApp from './pages/public/PublicApp.tsx';
import ErrorPage from './pages/error-page/ErrorPage.tsx';
import ProtectedRoute from './auth/ProtectedRoute.tsx';
import LoginPage from './pages/public/login-page/LoginPage.tsx';
import PrivateApp from './pages/private/PrivateApp.tsx';
import NotFoundPage from './pages/not-found-page/NotFoundPage.tsx';
import CreateOrderPage from './pages/private/create-order-page/CreateOrderPage.tsx';

const RoutesDentist = () => {
  // Redux authentication boolean and Firebase state of authentication to improve the security.
  const isAuthenticated = useAppSelector(isAuthenticatedSelector) && isCurrentUser();

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/order/create" />} />
      <Route element={<PublicApp />} errorElement={<ErrorPage />}>
        <Route
          path="login"
          element={
            <ProtectedRoute redirectPath="/order/create" isAllowed={!isAuthenticated}>
              <LoginPage />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute redirectPath="/login" isAllowed={isAuthenticated}>
            <PrivateApp />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}>
        <Route index path="/order/create" element={<CreateOrderPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default RoutesDentist;
