import { useAppSelector } from '../../../../store/hooks';
import { orderSelector } from '../../../../store/orders/orders.selectors';
import { useEffect, useState } from 'react';
import {
  useGetOneManufacturingOrderQuery,
  useGetProductionZipFileMutation
} from '../../../../services/manufacturing-orders-api.services.tsx';
import { WorkflowPostModelingStepEnum } from '../../../../enum/workflow-step.ts';
import FileSaver from 'file-saver';
import { history } from '../../../../models/history.tsx';
import { useNavigate } from 'react-router-dom';
import styles from './export.module.scss';
import { Box, Button, Viewer } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import JSZip from 'jszip';
import { OrderFile } from '../../../../models/order.tsx';
import { isThumbprintExtension, mapFileToOrderFile } from '../../../../utils/file.utils.ts';
import { ColorPropsEnum } from '../../../../enum/color.ts';
import { useDownloadFileFromStorageMutation } from '../../../../services/files-api.services.ts';

const ExportStep = () => {
  const orderSelect = useAppSelector(orderSelector);

  history.navigate = useNavigate();
  const { t } = useTranslation(['order']);

  const [file3dToDisplay, setFile3dToDisplay] = useState<OrderFile>();
  const [skipManufacturingOrderQuery, setSkipManufacturingOrderQuery] = useState<boolean>(false);

  const [downloadFromStorage, { isLoading: isDownloadingFromStorage }] =
    useDownloadFileFromStorageMutation();
  const [getProductionZipFile, { isLoading: isDownloadingProductionFiles }] =
    useGetProductionZipFileMutation();
  // we query order => until we reach validation step => then allow query on manufacturing order and skip order
  const { data: manufacturingOrder } = useGetOneManufacturingOrderQuery(orderSelect?.orderNumber, {
    pollingInterval: 2000,
    skip: skipManufacturingOrderQuery
  });

  useEffect(() => {
    if (manufacturingOrder?.currentStep === WorkflowPostModelingStepEnum.DELIVERED) {
      setSkipManufacturingOrderQuery(true);
      handleDownload();
    }
  }, [manufacturingOrder]);

  const handleDownload = async () => {
    if (manufacturingOrder) {
      const productionZipFileUrl = await getProductionZipFile(manufacturingOrder.orderNumber);
      if (productionZipFileUrl?.data) {
        const zipFile = await downloadFromStorage({ url: productionZipFileUrl.data });
        if (zipFile?.data) {
          const zipFileName = `${manufacturingOrder?.orderNumber}.zip`;
          loadProductionFilesInViewer(zipFile.data as Blob);
          FileSaver.saveAs(zipFile.data as Blob, zipFileName);
        }
      }
    }
  };

  const getFileName = (fileNameWithDirectory: string): string | undefined => {
    const fileNameWithDirectorySplit = fileNameWithDirectory?.split('/');
    if (fileNameWithDirectorySplit?.length) {
      return fileNameWithDirectorySplit[fileNameWithDirectorySplit.length - 1];
    }
  };

  const getFileExtension = (fileName: string | undefined): string | undefined => {
    const fileNameSplit = fileName?.split('.');
    return fileNameSplit?.length ? fileNameSplit[fileNameSplit.length - 1] : undefined;
  };

  const loadProductionFilesInViewer = async (zipData: Blob) => {
    const zip = new JSZip();
    const zipContent = await zip.loadAsync(zipData.arrayBuffer());

    for (const [fileNameWithDirectory, zipObject] of Object.entries(zipContent.files)) {
      const fileName = getFileName(fileNameWithDirectory);
      const fileExtension = getFileExtension(fileName);
      if (fileName && fileExtension && isThumbprintExtension(fileExtension)) {
        const blob: Blob = await zipObject.async('blob');
        const file: File = new File([blob], fileName);
        setFile3dToDisplay(mapFileToOrderFile(file, false));
      }
    }
  };

  const createNewOrder = (): void => {
    if (history.navigate) history.navigate('/');
  };

  const isDownloading = (): boolean =>
    isDownloadingProductionFiles || isDownloadingFromStorage || !skipManufacturingOrderQuery;

  return (
    <div className={styles['export']}>
      <Box color={ColorPropsEnum.GREY} className={styles['export__viewer']}>
        <Viewer
          noFileLabel={t('createOrder.no-file')}
          file3D={file3dToDisplay}
          isLoading={isDownloading()}
          className={styles['export__viewer__content']}
        />
      </Box>
      <div className={styles['export__title']}>
        <Button
          label={t(`createOrder.streamingFeature.export.${isDownloading() ? 'title' : 'action'}`)}
          size="s"
          onClick={() => handleDownload()}
          isLoading={isDownloading()}
        />
        <Button
          label={t('createOrder.action')}
          category="secondary"
          size="s"
          onClick={() => createNewOrder()}
        />
      </div>
    </div>
  );
};

export default ExportStep;
