import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files
import commonEN from './translations/en/common.json';
import authEN from './translations/en/auth.json';
import errorEN from './translations/en/error.json';
import notFoundEN from './translations/en/page_not-found.json';
import orderEN from './translations/en/page-order.json';

//Creating object with the variables of imported translation files
const resources = {
  en: {
    common: commonEN,
    auth: authEN,
    notFound: notFoundEN,
    error: errorEN,
    order: orderEN
  }
};

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', //default language
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
